async function getData (sheet_name : string, id? : number | null| string)  {
    

    const api = "https://script.google.com/macros/s/AKfycbwAoOgrsf4PlCy4Hi7sg6D3gUxfzmCeuTl8Bj3suht4cGIFNGLKpLniaJ3jHHMP2r2g/exec"
    let res : any ;
    
    try{
        if(id){
            res =  await fetch(`${api}?action=doGet&sheet_name=${sheet_name}&id=${id}`, {
                method : "GET"
            })

            const response = await res.json();
            console.log(response , "response");
            return response;

        }else{
            res =  await fetch(`${api}?action=doGet&sheet_name=${sheet_name}`, {
                method : "GET"
            })

            const response = await res.json();
            console.log(response , "response");
            return response;
        }
    
    }catch(err){
        console.error(err);
    }

   
}

export async function createNewResume(
    data : any
){
    

    const api = "https://script.google.com/macros/s/AKfycbzVapx3yqykKVwkxKqi3HhJAhmeQL6xOc3liJR6yEBZkIFvnYRrzITbju-NK2Pecss/exec";
    let res : any;

    try{
        console.log(data.id)
        if(data.id){
            res =  await fetch(`${api}?action=doPost`, {
                method : "POST",
                headers : {
                    "Content-Type": "text/plain;charset=utf-8"
                },
                redirect : "follow",
                body : JSON.stringify(data)
            })

            const response = await res.json();
            return response;

        }
    
    }catch(err){
        console.error(err);
    }
    
}

export async function updateResume(
    data : any
){
    

    const api = "https://script.google.com/macros/s/AKfycbzVapx3yqykKVwkxKqi3HhJAhmeQL6xOc3liJR6yEBZkIFvnYRrzITbju-NK2Pecss/exec";
    let res : any;

    try{
        console.log(data.id)
        if(data.id){
            res =  await fetch(`${api}?action=doPost`, {
                method : "POST",
                headers : {
                    "Content-Type": "text/plain;charset=utf-8"
                },
                redirect : "follow",
                body : JSON.stringify(data)
            })

            const response = await res.json();
            return response;

        }
    
    }catch(err){
        console.error(err);
    }
    
}






export async function getResumeData(
    email : string 
){
    const api = "https://script.google.com/macros/s/AKfycbyXlV8azT-xCfHQ_XqG7bgs-Ipk9NzKnzwE5KTzNhNowbY6Ok7Ci-1XiT35RQUn1Ofs/exec";
    let res : any;

    try{
        if(email){
            console.log(email);
            res =  await fetch(`${api}?action=doGet&user_id=${email}`, {
                method : "GET"
            })

            const response = await res.json();
            console.log(response);
            return response;

        }
    
    }catch(err){
        console.error(err);
    }
    
}


export async function registerClickData(
    sheet_name : string,
    email : string,
    name : string,
    id : number,
    whatsappNumber? : string
){

    const api = "https://script.google.com/macros/s/AKfycbyF2GiQ3fv1u_8IRTbh0k8uUgkEoS8vl0Ke_ygD6373tJFP04_wVBu19uN2QqE0E6db/exec";
    let res : any;

    try{
        console.log(id)
        if(id){
            res =  await fetch(`${api}?action=doPost`, {
                method : "POST",
                headers : {
                    "Content-Type": "text/plain;charset=utf-8"
                },
                redirect : "follow",
                body : JSON.stringify({
                    sheet_name,
                    email,
                    name,
                    id,
                    whatsappNumber
                })
            })

            const response = await res.json();
            return response;

        }
    
    }catch(err){
        console.error(err);
    }
}


export async function getClickData(userEmail : string | undefined ){

    const api = "https://script.google.com/macros/s/AKfycbyF2GiQ3fv1u_8IRTbh0k8uUgkEoS8vl0Ke_ygD6373tJFP04_wVBu19uN2QqE0E6db/exec"
    let res : any ;
    
    try{
        if(userEmail){
            console.log(userEmail);
            res =  await fetch(`${api}?action=doGet&email=${userEmail}`, {
                method : "GET"
            })

            const response = await res.json();
            console.log(response);
            return response;

        }
    
    }catch(err){
        console.error(err);
    }
}   
export default getData;